<template>
    <loading 
        :active.sync="isLoading"
        :can-cancel="true"
        :is-full-page="fullPage"
        :loader="type"
    ></loading>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    name: 'loader',
    props: {
        isLoading: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: 'dots'
        }
    },
    components: {
        Loading
    },
    data() {
        return {
            fullPage: true
        }
    }
}
</script>

<!-- <style scoped src="vue-loading-overlay/dist/css/index.css"></style> -->