<template>
    <div class="row">
        <div class="col-12">
            <div class="page-title-box d-flex align-items-center justify-content-between">
                <h4 class="page-title mb-0 font-size-18">{{ title }}</h4>
                        
                <div class="page-title-right d-flex align-items-center" v-if="subtitle">
                    <div class="page-title" style="margin-right: 10px;" v-if="isDashboard">
                        <select name="" id="" class="form-control" @change="selectBudget">
                            <option disabled selected>Pilih Anggaran</option>
                            <option v-for="(value, index) in budgets" :key="index" :value="JSON.stringify(value)" :selected="budgetId == value.id">{{ `${value.year} - ${$format.formatRupiah(value.nominal)}` }}</option>
                        </select>
                    </div>
                    <h4 class="page-title mb-0 font-size-18">{{ subtitle }}</h4>
                </div>

                <div class="page-title-right" v-if="breadcrumbs">
                    
                    <ol class="breadcrumb m-0">
                        <li 
                            v-for="(breadcrumb, index) in breadcrumbs"
                            :key="index"
                            class="breadcrumb-item"
                            :class="{
                                'active': index == breadcrumbs.length - 1
                            }"
                        >
                            {{ breadcrumb }}
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeaderIndex',
    props: {
        budgetId: {
            type: Number,
            required: false
        },
        budgets: {
            type: Array,
            required: false
        },
        isDashboard: {
            type: Boolean,
            required: false,
        },
        title: {
            type: String,
            required: true
        },
        subtitle: {
            type: String,
            required: false
        },
        breadcrumbs: {
            type: Array,
            required: false
        },
        selectBudget: {
            type: Function,
            required: false
        },
    }
}
</script>