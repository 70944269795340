import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import moment from "moment"
import { BootstrapVue, IconsPlugin } from "bootstrap-vue"
import CKEditor from "@ckeditor/ckeditor5-vue2"
import { components } from "@/plugins/components"
import api from "@/plugins/api"
import utils from "@/plugins/utils"
import errorCaptureMixin from '@/mixins/errorCaptureMixin.js';

import "leaflet/dist/leaflet.css"
import "leaflet-geosearch/assets/css/leaflet.css"

import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-vue/dist/bootstrap-vue.css"

import "../public/assets/libs/sweetalert2/sweetalert2.min.css"
import "../public/assets/css/bootstrap.min.css"
import "../public/assets/css/icons.min.css"
import "../public/assets/css/app.min.css"

import "../public/assets/libs/sweetalert2/sweetalert2.min.js"
import "../public/assets/libs/jquery/jquery.min.js"
import "../public/assets/libs/bootstrap/js/bootstrap.bundle.min.js"
import "../public/assets/libs/metismenu/metisMenu.min.js"
import "../public/assets/libs/jquery-sparkline/jquery.sparkline.min.js"

import { VueReCaptcha } from "vue-recaptcha-v3"
import VueCurrencyInput from "vue-currency-input"

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.use(CKEditor)
Vue.use(components)
Vue.use(api, { router })
Vue.use(utils)
Vue.use(VueCurrencyInput, {
  globalOptions: {
    currency: {
      prefix: "Rp. ",
    },
    locale: "en",
  },
})
Vue.use(VueReCaptcha, {
  siteKey: "6LcHGCAaAAAAAJw5nPscIjSjY3XpGnPOnSWpucP7",
  loaderOptions: {
    useRecaptchaNet: true,
    autoHideBadge: true,
  },
})

Vue.mixin(errorCaptureMixin)

Vue.prototype.moment = moment

window.axios = require("axios")
window.$ = require("jquery")

Vue.config.productionTip = false

new Vue({
  router,
  render: function (h) {
    return h(App)
  },
}).$mount("#app")
