import axios from 'axios'
import alert from '@/utils/alert'

/**
 * @description service to call HTTP request via Axios
 */
export default {
	install: (Vue, options) => {
		const router = options.router

		const api = axios.create({
			baseURL: `${process.env.VUE_APP_BASE_URL_API}/api`,
		})

		api.interceptors.request.use(
			(config) => {
				let token = null
				const userData = localStorage.getItem('userData')
	
				if(userData) {
					token = JSON.parse(userData).access_token
				}
	
				if (token != null) {
					config.headers['Authorization'] = `Bearer ${token}`
				}
	
				return config
			},
			(error) => {
				return Promise.reject(error)
			}
		)
	
		api.interceptors.response.use(
			(response) => response,
			async (err) => {			
				if (err.response != undefined) {
					// Logout if access token is expired
					if (err.response.status === 401 || err.response.data.message == 'Token is Invalid' || err.response.data.message == 'Authorization Token not found') {
						try {
							localStorage.removeItem('userData')
							router.push('/auth/login').catch(()=>{});
						} catch (e) {
							localStorage.removeItem('userData')
							router.push('/auth/login').catch(()=>{});
							return Promise.reject(e)
						}
					} else if(err.response.status === 422 && err.response.data.errors) {
						let message = ''
						const errors = Object.values(err.response.data.errors)
						
						errors.forEach((error) => {
							if(Array.isArray(error)) {
								error.forEach((e) => {
									console.log(e)
									message += `${e}\n`
								})
							} else {
								message += `${error}\n`
							}
						})
	
						alert.showMessage(message, 'warning', 'top-end', 5000)
					} else if(err.response.data.message && err.response.data.message != 'Token is Invalid') {
						let message = 'Bad Request'
						if(err.response.data.message) message = err.response.data.message
	
						alert.showMessage(message, 'warning')
					}
				}
	
				return Promise.reject(err)
			}
		)

		Vue.prototype.$api = api
	}
}
