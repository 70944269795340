<template>
    <div class="row mt-3 mb-2">
        <!-- Search -->
        <div class="col-sm-12 col-md-6">
            <div id="tickets-table_filter" class="dataTables_filter">
                <label class="">
                    Pencarian:<br>
                    <b-form-input v-model="searchValue" type="search" placeholder="Cari data..." class="form-control"></b-form-input>
                </label>
            </div>
        </div>
        <!-- End search -->
        <div class="col-sm-12 col-md-6">
            <div id="tickets-table_length" class="dataTables_length form-data text-md-end">
                <label class="">
                    Data Per Halaman:<br>
                    <b-form-select v-model="perPageValue" :options="pageOptions" class="form-control" style="display: block"></b-form-select>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopTable',
    props: {
        search: {
            type: String,
            default: null
        },
        perPage: {
            type: Number,
            default: 10
        },
        pageOptions: {
            type: Array            
        }
    },
    computed: {
        searchValue: {
            get() {
                return this.search
            },
            set(newValue) {
                this.$emit('update-search', newValue)
            }
        },
        perPageValue: {
            get() {
                return parseInt(this.perPage)
            },
            set(newValue) {
                this.$emit('update-perPage', parseInt(newValue))
            }
        }
    }
}
</script>