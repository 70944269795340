export default {
	showMessage(message, icon = 'success', position = 'top-end', timer = 1500) {
		return Swal.fire({
			position: position,
			type: icon,
			title: message,
			showConfirmButton: false,
			timer: timer,
		})
	},
	showConfirm(
		title = 'Are you sure?', 
		message = "You won't be able to revert this!",
		confirmText = 'Yes',
		cancelText = 'Cancel'
	) {
		return Swal.fire({
			title,
			text: message,
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: confirmText,
			cancelButtonText: cancelText
		})
	}
}
