export default {
    formatRupiah(value) {
        let val = value
        if (value == null || value == undefined) val = 0
        const price = parseFloat(val);
        const formattedPrice = price.toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
        });
        return formattedPrice;
    },
    formatPackageType(value) {
        switch (value) {
            case "planning":
                return "Perencanaan"
            case "development":
                return "Pembangunan"
            case "supervision":
                return "Pengawasan"                    
            default:
                break;
        }
        return "-"
    },
    transApproval(status) {
        switch(status) {
            case 'Approve':
                return 'Terverifikasi'
            case 'Reject':
                return 'Tidak Terverifikasi'
            default:
                return 'Menunggu Verifikasi'
        }
    },
}