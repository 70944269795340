<template>
    <router-view />
</template>

<script>
export default {
    name: 'App',    
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a.router-link-exact-active {
    background-color: #4b4b4b42;
}

#page-topbar {
    background-color: #000000 !important;
}

body[data-layout=detached] #layout-wrapper::before {
    background: #4b4b4b !important;
}
.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a.control-sidebar:hover {
    width: 70px;
    border-top-left-radius: 7px;
    border-top-right-radius: 7px;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a:hover {
    background-color: #d4d3d3!important;
    color: #000!important;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a>i:hover {
    color: #000!important;
}

.vertical-collpsed .vertical-menu #sidebar-menu>ul>li>a>i {
    color: #707d8a!important;
}

.bg-login-overlay {
    background: #4b4b4b !important;
}

.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: #3b5de7 !important;
}

.nav-link.active:hover {
    color: #000 !important;
}

.nav-link:hover {
    color: #4b4b4b !important;
}

td.odd {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color);
    border-top-width: 1px;
    border-bottom-width: 0;
    padding: 0.75rem 0.75rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    -webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

td.even {
    padding: 0.75rem 0.75rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: 1px;
    -webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
</style>
