import Vue from 'vue'
import HeaderIndex from '@/components/general/HeaderIndex.vue'
import Loader from '@/components/general/Loader.vue'
import TopTable from '@/components/table/TopTable.vue'
import VueApexCharts from 'vue-apexcharts'

export const components = {
    install() {
        // General
        Vue.component('header-index', HeaderIndex)
        Vue.component('loader', Loader)

        // Table
        Vue.component('top-table', TopTable)

        // Apexchart
        Vue.use(VueApexCharts)
        Vue.component('apexchart', VueApexCharts)
    }
}